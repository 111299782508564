import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

class Timer extends React.Component {
  static propTypes = {
    until: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props);

    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = Date.parse(this.props.until);
    const secondDate = Date.now();

    const daysLeft = Math.floor(Math.abs((firstDate - secondDate) / oneDay));

    const oneHour = 60 * 60 * 1000;
    const hoursLeft = Math.floor(Math.abs((firstDate - secondDate - daysLeft * oneDay) / oneHour));
    
    const oneMinute = 60 * 1000;
    const minutesLeft = Math.floor(Math.abs((firstDate - secondDate - daysLeft * oneDay - hoursLeft * oneHour) / oneMinute));

    const oneSecond = 1000;
    const secondsLeft = Math.floor(Math.abs((firstDate - secondDate - daysLeft * oneDay - hoursLeft * oneHour - minutesLeft * oneMinute) / oneSecond));

    this.state = {
      d: daysLeft, 
      h: hoursLeft, 
      m: minutesLeft,
      s: secondsLeft,
      d_mx: {
        d: daysLeft + 1, 
        h: 23, 
        m: 59, 
        s: 59
      },
      clock_sz: 120,
      d_offset: 0,
      h_offset: 0,
      m_offset: 0,
      s_offset: 0
    }

    setInterval(this.decreaseTimer, 1000);
  }

  decreaseTimer = () => {
    let { d, h, m, s, clock_sz, d_mx } = this.state

    let new_d = d, new_h = h, new_m = m, new_s = s
    new_s--

    if (new_s === 0) {
      new_m--;
      new_s = d_mx.s;
      if (new_m === 0) {
        new_h--;
        new_m = d_mx.m;
        if (new_h === 0) {
          new_d--;
          new_h = d_mx.h;
        }
      }
    }

    this.setState({
      d: new_d,
      h: new_h,
      m: new_m,
      s: new_s,
      d_offset: clock_sz-(d*(clock_sz/d_mx.d)),
      h_offset: clock_sz-(h*(clock_sz/d_mx.h)),
      m_offset: clock_sz-(m*(clock_sz/d_mx.m)),
      s_offset: clock_sz-(s*(clock_sz/d_mx.s))
    })
  }

  render () {
    let { d, h, m, s, d_offset, h_offset, m_offset, s_offset } = this.state
    const headStatus = classNames('session-head__status', {
      'session-head__status--active': Date.parse(this.props.until) > Date.now()
    })
    const headText = Date.parse(this.props.until) > Date.now() ? 'Active' : 'Ended'
    const timerContainerClasses = classNames('session-head__timer-container', {
      hidden: Date.parse(this.props.until) <= Date.now()
    })

    return (
      <div className="session-head__timer">
        <span className={headStatus}>{headText}</span>
        <div className={timerContainerClasses}>
          <div className="session-head__number">
            <span className="session-head__timer-d">{d}</span>
            <svg width="42" height="42" xmlns="http://www.w3.org/2000/svg">
              <g>
                <title>Clock</title>
                <circle className="session-head__timer--circle d" r="19" cy="21" cx="21" strokeWidth="2" style={{"strokeDashoffset": d_offset}}></circle>
              </g>
            </svg>

          </div>
          <div className="session-head__number">
            <span className="session-head__timer-h">{h}</span>
            <svg width="42" height="42" xmlns="http://www.w3.org/2000/svg">
              <g>
                <title>Clock</title>
                <circle className="session-head__timer--circle h" r="19" cy="21" cx="21" strokeWidth="2" style={{"strokeDashoffset": h_offset}}></circle>
              </g>
            </svg>

          </div>
          <div className="session-head__number">
            <span className="session-head__timer-m">{m}</span>
            <svg width="42" height="42" xmlns="http://www.w3.org/2000/svg">
              <g>
                <title>Clock</title>
                <circle className="session-head__timer--circle m" r="19" cy="21" cx="21" strokeWidth="2" style={{"strokeDashoffset": m_offset}}></circle>
              </g>
            </svg>

          </div>
          <div className="session-head__number">
            <span className="session-head__timer-s">{s}</span>
            <svg width="42" height="42" xmlns="http://www.w3.org/2000/svg">
              <g>
                <title>Clock</title>
                <circle className="session-head__timer--circle s" r="19" cy="21" cx="21" strokeWidth="2" style={{"strokeDashoffset": s_offset}}></circle>
              </g>
            </svg>

          </div>
        </div>
      </div>
    )
  }
}

export default Timer