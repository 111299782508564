import React from "react"
import PropTypes from "prop-types"
import { truncateString, hashSubset } from "../../utils"
import ItemForm from './ItemForm'
import classNames from "classnames"

class SubmitItemForm extends ItemForm {
  render () {
    const loadingClasses = classNames('loading', {hidden: !this.props['uploading']})
    const confirmClasses = classNames('add-item__confirm', {hidden: this.props['uploading']})

    return (
      <React.Fragment>
        <form className="add-item" onClick={this.maybeCloseForm.bind(this)}>
          <div className="add-item__close" onClick={this.closeForm.bind(this)}>
            <i className="fa fa-close"></i>
          </div>
          <div className="add-item__container">
            <div className="row">
              <label htmlFor="ai-name">Name</label>
              <input id="ai-name" name="name" type="text" required="" defaultValue={this.state.name} onChange={this.handleChange.bind(this)} />
            </div>
            <div className="row">
              <label htmlFor="ai-description">description</label>
              <textarea id="ai-desc" name="description" defaultValue={this.state.description} rows="3" required="" onChange={this.handleChange.bind(this)} />
            </div>

            <div className="row hidden">
              <label htmlFor="ai-imageUrl">Image URL</label>
              <input id="ai-imageUrl" name="image_url" type="text" required="" defaultValue={this.state.image_url} onChange={this.handleChange.bind(this)} />
            </div>
            <div className="row">
              <label htmlFor="ai-imageUrl">Image</label>
              <input id="ai-image" name="image" type="file" required="" onChange={this.handleChange.bind(this)} accept="image/x-png,image/gif,image/jpeg" />
            </div>
            <div className="row">
              <label htmlFor="ai-webUrl">Web URL</label>
              <input id="ai-webUrl" name="web_url" type="text" required="" defaultValue={this.state.web_url} onChange={this.handleChange.bind(this)} />
            </div>

            <div className="row">
              <label htmlFor="ai-price">Price</label>
              <input id="ai-price" name="price" defaultValue={this.state.price} type="number" min="0.01" step="0.01" required="" onChange={this.handleChange.bind(this)} />
            </div>
            <div className="row add-item__measurement">
              <label htmlFor="ai-qty">Item Qty.</label>
              <input id="ai-qty" name="quantity" defaultValue={this.state.quantity} type="number" step="0.01" onChange={this.handleChange.bind(this)} />
              <select id="ai-measurement" name="quantity_unit" defaultValue={this.state.quantity_unit && this.state.quantity_unit.toLowerCase().substr(0,5)} onChange={this.handleChange.bind(this)} >
                <option value="L">L</option>
                <option value="count">count</option>
                <option value="fl. oz">fl. oz</option>
                <option value="g">g</option>
                <option value="kg">kg</option>
                <option value="lb">lb</option>
                <option value="mL">mL</option>
                <option value="oz" >oz</option>
                <option value="set">set</option>
                <option value="unit">unit</option>
                <option value="pkg">pkg</option>
                <option value="box">box</option>
                <option value="case">case</option>
              </select>
            </div>
            <div className="row add-item__amount">
              <label htmlFor="ai-split">Total Splits</label>
              <input id="ai-total-split" name="totalSplits" type="text" readOnly={true} placeholder={(this.state.num_splits * this.state.max_items) || 0} />
            </div>
            <div className="add-item__split-details">
              <div className="add-item__cost-split">{this.state.priceUnit}{this.splitPrice()}</div>
              <div className="add-item__qty-split" data-measurement={this.state.quantity_unit && this.state.quantity_unit.substr(0,5)}>{this.splitQuantity()}</div>
            </div>
            <div className="row center add-item__action">
              <p className="add-item__disclaimer"><span>*</span>All prices are approximates</p>
              <button
                className={confirmClasses}
                onClick={this.addItemToSession.bind(this)}
                disabled={this.props.uploading}
              >
                  Update
              </button>
              <div className={loadingClasses}></div>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default SubmitItemForm
