import axios from 'axios'

function jax(path, type, data, done) {
  $.ajax({
    url: path,
    type: type,
    data: data,
    xhrFields: { withCredentials: true }
  }).
  done(done).
  fail(function(r) {
    console.log('jax fail: ', {path: path, data: data, response: r});
  });
}

export function fetchCategories(callback) {
  jax(
    "/items/categories",
    "GET",
    {},
    function(r) { callback(r) }
  )
}

export function fetchItems(category, vendor, callback) {
  jax(
    "/items",
    "GET",
    { category: category, vendor: vendor },
    function(r) { callback(r) }
  )
}

export function searchItems(terms, vendor, callback) {
  jax(
    "/items/search",
    "GET",
    { terms: terms, vendor: vendor },
    function(r) { callback(r) }
  )
}

export function inviteToSession(sessionId, emails, callback) {
  jax(
    "/split_sessions/" + sessionId + "/users",
    "POST",
    { emails },
    callback
  )
}

export function submitSessionItems(sessionId, session_items, callback) {
  var formData = new FormData()
  session_items.forEach((item, i) => {
    for (let [key, value] of Object.entries(item)) {
      if (value) {
        formData.append(`session_items[${i}][${key}]`, value)
      }
    }
  })

  axios({
      method: 'POST',
      url: "/split_sessions/" + sessionId + "/set_items",
      data: formData,
      xhrFields: { withCredentials: true }
    })
    .then(response => callback(response.data))
    .catch(error => {
      console.log('jax fail: ', {path: "/split_sessions/" + sessionId + "/set_items", data: formData, response: error});
    })
}

export function removeSplitSessionItem(splitSessionItemId, callback) {
  jax(
    "/split_session_items/" + splitSessionItemId,
    "DELETE",
    null,
    callback
  )
}