import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"


class ClaimsSummaryItem extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    myClaimSplits: PropTypes.number,
    handleEdit: PropTypes.func.isRequired
  }

  handleClick = () => {
    this.props.handleEdit(this.props.item.id)
  }

  render () {
    const {item, myClaimSplits} = this.props
    const containerClasses = classNames('request-item', {
      hidden: myClaimSplits == 0
    })

    return (
      <div className={containerClasses} onClick={this.handleClick}>
        <div className="request-item__information">
          <div className="request-item__edit">Edit</div>
          <div className="request-item__branding">
            <div className="request-item__name">{item.name}</div>
            <div className="request-item__brand">{item.item.brand}</div>
          </div>
          <div className="request-item__product-qc">
            <div className="request-item__qty">{item.split_quantity * myClaimSplits} {item.quantity_unit}</div>
            <div className="request-item__cost">{item.item.price_unit || '$'}{parseFloat(item.split_price * myClaimSplits).toFixed(2)}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default ClaimsSummaryItem