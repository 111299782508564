import React from "react"
import PropTypes from "prop-types"

import classNames from 'classnames'
import { inviteToSession } from '../../actions'

class SessionParticipants extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      sessionId: props.session_id,
      email: '',
      emails: props.emails || []
    }
  }

  handleChangeEmail = (e) => {
    this.setState({
      email: e.target.value
    })
  }

  handleAddEmail = (e) => {
    e.preventDefault()

    if (this.state.emails.find(email => email.address == this.state.email) || this.state.email.trim().length == 0) {
      this.setState({
        email: ''
      })
    } else {
      this.setState(state => {
        const emails = [...state.emails, {address: state.email.trim(), invited: false}]

        return {
          emails,
          email: ''
        }
      })
    }
  }

  handleRemoveEmail = (email) => {
    this.setState(state => {
      const emails = state.emails.filter(item => item.address != email)

      return {
        emails
      }
    })
  }

  handleSendInvites = () => {
    const emails = this.state.emails.filter(email => !email.invited).map(email => email.address)
    if (emails.length == 0) return

    console.log(this.state, 'before')
    inviteToSession(this.state.sessionId, this.state.emails.map(email => email.address), (data) => {
      this.setState(state => {
        const emails = state.emails.map(email => {
          if(data.users.find(user => user.email.trim() == email.address.trim())) {
            return {
              ...email,
              invited: true
            }
          } else {
            return email
          }
        })

        return {
          emails
        }
      })
    })
    console.log(this.state, 'after')
  }

  render () {
    const participants = this.state.emails.map((email, key) => {
      let liClasses = classNames({'invited': email['invited']})

      return (
        <li key={key} className={liClasses}>
          { !email.invited && <div className="remove-invite" onClick={e => this.handleRemoveEmail(email.address)}></div> }
          {email.address}
        </li>
      )
    })

    return (
      <React.Fragment>
        <div className="create__invite create__invite--expanded">
          <div className="s-invite__header">
            <div className="create__invite-count">
              <i className="fa fa-user"></i>
              <span>{ this.state.emails.length }</span>
            </div>
            <h2 className="create__invite-header">Session Participants</h2>
          </div>
          <div className="s-invite">
            <div className="s-invite__emails">
              <form action="#s-email" className="s-invite__add-e" onSubmit={this.handleAddEmail}>
                <label>Enter an Email (form still stubbed)</label>
                <div className="row">
                  <input id="s-email" type="email" placeholder="Invite people to this session" value={this.state.email} onChange={this.handleChangeEmail} />
                  <input type="submit" value="Add to Session" />
                </div>
              </form>
              <div className="s-invite__invited">
                <p className="s-invite__label">Invited Participants</p>
                <ul className="s-invite__participants">
                  { participants }
                </ul>
                <button className="s-invite__action-send" onClick={this.handleSendInvites}>Send Invites</button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

SessionParticipants.propTypes = {
  sessionId: PropTypes.number,
  email: PropTypes.string,
  emails: PropTypes.array
};

export default SessionParticipants