import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { truncateString, hashSubset } from "../../utils"
class ItemForm extends React.Component {
  static propTypes = {
    handleItemAdded: PropTypes.func.isRequired,
    handleFormClosed: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
    uploading: PropTypes.bool
  }

  constructor(props) {
    super(props);

    let { item } = this.props

    this.state = {
      id: item.id,
      item_id: item.item ? item.item.id : undefined,
      name: item.name,
      price: item.price,
      price_unit: item.price_unit,
      quantity: item.quantity,
      quantity_unit: item.quantity_unit || 'L',
      max_items: item.max_items || 1,
      num_splits: item.num_splits || 2,
      description: item.description,
      image_url: item.image_url,
      image: undefined,
      web_url: item.web_url,
      'item_attributes[description]': item.item ? item.item.description : undefined,
      'item_attributes[id]': item.item ? item.item.id : undefined
    } 
  }

  componentDidUpdate() {
  }

  handleChange(e) {
    var newState = {}

    var val = e.target.value, fieldName = e.target.name

    if (fieldName == "price") {
      newState[fieldName] = Number.parseFloat(val)
    } else if (fieldName == "image") {
      newState[fieldName] = e.target.files[0]
    } else {
      newState[fieldName] = val
    }

    this.setState(newState)
  }

  addItemToSession(e) {
    e.preventDefault();

    this.addCalculationsToState(() => {
      this.props.handleItemAdded(this.state, this.props.handleFormClosed)
    }) 
  }

  handleDecBtn(e) {
    var inputField = e.target.nextSibling
    var attrName   = inputField.name
    var currentVal = Number.parseFloat(inputField.value)
    var step       = Number.parseFloat(inputField.step)
    var newVal     = currentVal - step
    var data = {inputField: inputField, attrName: attrName, currentVal: currentVal, step: step, newVal: newVal }

    if (newVal >= Number.parseFloat(inputField.min))
      inputField.value = newVal

    this.handleChange({target: inputField})
  }

  handleIncBtn(e) {
    var inputField = e.target.previousSibling
    var attrName   = inputField.name
    var currentVal = Number.parseFloat(inputField.value)
    var step       = Number.parseFloat(inputField.step)
    var newVal     = currentVal + step
    var data = {inputField: inputField, attrName: attrName, currentVal: currentVal, step: step, newVal: newVal }

    if (!inputField.max || newVal <= Number.parseFloat(inputField.max))
      inputField.value = newVal

    this.handleChange({target: inputField})
  }

  closeForm() {
    this.props.handleFormClosed()
  }

  maybeCloseForm(e) {
    if (e.target.className == "add-item")
      this.closeForm()
  }

  splitPrice() {
    return(
      (this.state.price / this.state.num_splits).toFixed(2)
    )
  }

  splitQuantity() {
    return(
      (this.state.quantity / this.state.num_splits).toFixed(2)
    )
  }

  addCalculationsToState(callback) {
    this.setState({
      split_price:    this.splitPrice(),
      split_quantity: this.splitQuantity()
    }, callback)
  }

  render () {
    const loadingClasses = classNames('loading', {hidden: !this.props['uploading']})
    const confirmClasses = classNames('add-item__confirm', {hidden: this.props['uploading']})

    return (
      <React.Fragment>
        <form className="add-item" onClick={this.maybeCloseForm.bind(this)}>
          <div className="add-item__close" onClick={this.closeForm.bind(this)}>
            <i className="fa fa-close"></i>
          </div>
          <div className="add-item__container">
            <div className="row">
              <label htmlFor="ai-name">Name</label>
              <input id="ai-name" name="name" type="text" required="" defaultValue={this.state.name} onChange={this.handleChange.bind(this)} />
            </div>
            <div className="row">
              <label htmlFor="ai-description">description</label>
              <textarea id="ai-desc" name="description" defaultValue={this.state.description} rows="3" required="" onChange={this.handleChange.bind(this)} />
            </div>

            <div className="row hidden">
              <label htmlFor="ai-imageUrl">Image URL</label>
              <input id="ai-imageUrl" name="image_url" type="text" required="" defaultValue={this.state.image_url} onChange={this.handleChange.bind(this)} />
            </div>
            <div className="row">
              <label htmlFor="ai-imageUrl">Image</label>
              <input id="ai-image" name="image" type="file" required="" onChange={this.handleChange.bind(this)} accept="image/x-png,image/gif,image/jpeg" />
            </div>
            <div className="row">
              <label htmlFor="ai-webUrl">Web URL</label>
              <input id="ai-webUrl" name="web_url" type="text" required="" defaultValue={this.state.web_url} onChange={this.handleChange.bind(this)} />
            </div>

            <div className="row">
              <label htmlFor="ai-price">Price</label>
              <input id="ai-price" name="price" defaultValue={this.state.price} type="number" min="0.01" step="0.01" required="" onChange={this.handleChange.bind(this)} />
            </div>
            <div className="row add-item__measurement">
              <label htmlFor="ai-qty">Item Qty.</label>
              <input id="ai-qty" name="quantity" defaultValue={this.state.quantity} type="number" step="0.01" onChange={this.handleChange.bind(this)} />
              <select id="ai-measurement" name="quantity_unit" defaultValue={this.state.quantity_unit && this.state.quantity_unit.toLowerCase().substr(0,5)} onChange={this.handleChange.bind(this)} >
                <option value="L">L</option>
                <option value="count">count</option>
                <option value="fl. oz">fl. oz</option>
                <option value="g">g</option>
                <option value="kg">kg</option>
                <option value="lb">lb</option>
                <option value="mL">mL</option>
                <option value="oz" >oz</option>
                <option value="set">set</option>
                <option value="unit">unit</option>
                <option value="pkg">pkg</option>
                <option value="box">box</option>
                <option value="case">case</option>
              </select>
            </div>
            <div className="row add-item__amount">
              <label htmlFor="ai-max">Max Items</label>
              <i id="less-item" className="fa fa-minus-circle" onClick={this.handleDecBtn.bind(this)}></i>
              <input id="ai-max" type="number" name="max_items" min="1" step="1" defaultValue={this.state.max_items} required="" onChange={this.handleChange.bind(this)} />
              <i id="plus-item" className="fa fa-plus-circle" onClick={this.handleIncBtn.bind(this)}></i>
            </div>
            <div className="row add-item__amount">
              <label htmlFor="ai-split">Splits</label>
              <i id="less-split" className="fa fa-minus-circle" onClick={this.handleDecBtn.bind(this)}></i>
              <input id="ai-split" name="num_splits" type="number" min="1" step="1" defaultValue={this.state.num_splits} required="" onChange={this.handleChange.bind(this)} />
              <i id="plus-split" className="fa fa-plus-circle" onClick={this.handleIncBtn.bind(this)}></i>
            </div>
            <div className="row add-item__amount">
              <label htmlFor="ai-split">Total Splits</label>
              <input id="ai-total-split" name="totalSplits" type="text" readOnly={true} placeholder={(this.state.num_splits * this.state.max_items) || 0} />
            </div>
            <div className="add-item__split-details">
              <div className="add-item__cost-split">{this.state.priceUnit}{this.splitPrice()}</div>
              <div className="add-item__qty-split" data-measurement={this.state.quantity_unit && this.state.quantity_unit.substr(0,5)}>{this.splitQuantity()}</div>
            </div>
            <div className="row center add-item__action">
              <p className="add-item__disclaimer"><span>*</span>All prices are approximates</p>
              <button
                className={confirmClasses}
                onClick={this.addItemToSession.bind(this)}
                disabled={this.props.uploading}
              >
                  Confirm and Add
              </button>
              <div className={loadingClasses}></div>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default ItemForm
