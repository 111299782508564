import React from "react"
import PropTypes from "prop-types"
import { truncateString } from "../../utils"
class ManageSub extends React.Component {
  static propTypes = {
    cancelUrl: PropTypes.string.isRequired,
    stripePK:  PropTypes.string.isRequired,
    sessionId: PropTypes.string.isRequired,
  }
  constructor(props) {
    super(props);
    this.state = {
      stripe: Stripe(props.stripePK)
    }
  }

  handleBtnClick = (e) => {
    e.preventDefault()
    console.log("component btn clicked", this.props.sessionId)
    this.state.stripe.redirectToCheckout({
      sessionId: this.props.sessionId
    }).then(function (result) {
      console.log("redirectResult", result)
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    });
  }

  render() {
    return(
      <React.Fragment>
        <a
          id="manage-subscription"
          className="subscription__action"
          onClick={this.handleBtnClick}
        >Change your payment method</a>
        <br/>
        <br/>
      </React.Fragment>
    )
  }
}

export default ManageSub
