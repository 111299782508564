import React from "react"
import PropTypes from "prop-types"
import { truncateString } from "../../utils"
class ItemPickerCart extends React.Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    handleItemEdited: PropTypes.func.isRequired,
    handleItemRemoved: PropTypes.func.isRequired
  }
  constructor(props) {
    super(props);
  }

  removeFromCart = (item) => {
    this.props.handleItemRemoved(item)
  }

  editItemInCart = (item) => {
    this.props.handleItemEdited(item)
  }


  renderCartItems() {
    if (!this.props.items || this.props.items.length == 0) {
      return("")
    } else {
      return this.props.items.map((item, index) => {
        return(
          <li key={index}>
            <div className="item-picker__cart-item">
              <div className="ci-options">
                <div className="ci-edit" onClick={() => this.editItemInCart(item)}>Edit</div>
                <div className="ci-remove">
                  <i className="fa fa-times-circle" onClick={() => this.removeFromCart(item)}></i>
                </div>
              </div>
              <div className="ci-name">{truncateString(item.name, 30)}</div>
              <div className="ci-details">
                <div className="ci-split">{item.priceUnit}{parseFloat(item.price).toFixed(2)}</div>
                <div className="ci-qty" data-measurement={truncateString(item.quantityUnit || '', 5)}>{item.quantity}</div>
              </div>
            </div>
          </li>
        )
      })
    }
  }

  render () {
    return (
      <React.Fragment>
        <ul className="item-picker__cart">
         {this.renderCartItems()}
        </ul>
      </React.Fragment>
    )
  }
}
export default ItemPickerCart
