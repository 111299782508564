import React from "react"
import PropTypes from "prop-types"
import DatePicker from "react-datepicker"
import moment from "moment"


class NameAndDateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.name,
      description: props.description,
      duration: props.duration,
      distribution_details: props.distribution_details,
      startDate: props.startDate ? moment(props.startDate).toDate() : new Date()
    };
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  handleDateChange(date) {
    this.setState({
      startDate: date
    });
  }

  handleDurationChange(e) {
    this.setState({
      name: e.target.value
    });
  }

  handleSubmit(e) {
    alert('form was submitted: ', { e: e, state: this.state });
    event.preventDefault();
  }

  render () {
    return (
      <React.Fragment>
        <form
          className="create__session-name create__session-name--expanded"
          id="new_split_session"
          action={this.props.url}
          acceptCharset="UTF-8"
          method="post"
        >
          <input name="utf8" type="hidden" value="✓" />
          <input type="hidden" name="authenticity_token" value={this.props.authenticityToken} />
          <input type="hidden" name="_method" value={this.props.httpMethod} />

          <div className="row s-name">
            <label htmlFor="name">Name your session</label>
            <input
              placeholder="Janie's Session"
              defaultValue={this.state.name}
              required="required"
              id="s-name"
              type="text"
              name="split_session[name]"
            />
          </div>
          <div className="row s-name">
            <label htmlFor="description">Description</label>
            <input
              placeholder="Short Description"
              defaultValue={this.state.description}
              required="required"
              id="s-name"
              type="text"
              name="split_session[description]"
            />
          </div>
          <div className="row s-name">
            <label htmlFor="description">Distribution details</label>
            <textarea
              placeholder="Location, time, what to bring, where to pick up, how reimbursement will take place, etc"
              defaultValue={this.state.distribution_details}
              required="required"
              name="split_session[distribution_details]"
            >
            </textarea>
          </div>
          <div className="row s-time-columns">
            <div className="create__sn-start">
              <label htmlFor="start_date">Session start</label>
              <DatePicker
                selected={this.state.startDate}
                onChange={this.handleDateChange}
                minDate={new Date()}
                showTimeSelect
                timeIntervals={60}
              />
              <input type="hidden" name="split_session[start_date]" value={this.state.startDate} />
              </div>
              <div className="create__sn-duration">
                <label htmlFor="duration">Session duration</label>
                <select
                  name="split_session[duration]"
                  id="split_session_duration"
                  defaultValue={this.state.duration}
                  onChange={this.handleDurationChange.bind(this)}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                </select>
                <span className="s-d-label">Days</span>
              </div>
          </div>
          <div className="row center">
            <button type="submit" className="create__s-submit" >
                Save Session
            </button>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

NameAndDateForm.propTypes = {
  name: PropTypes.string,
  authenticityToken: PropTypes.string,
  startDate: PropTypes.string
};
export default NameAndDateForm
