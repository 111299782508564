import React from "react"
import PropTypes from "prop-types"
import ItemPickerCategories from "./ItemPickerCategories"
import ItemPickerCart from "./ItemPickerCart"
import ItemPickerList from "./ItemPickerList"
import ItemForm from "./ItemForm"
import { fetchItems, fetchCategories, searchItems, submitSessionItems, removeSplitSessionItem } from "../../actions"
import { hashSubset } from "../../utils"

class ItemPicker extends React.Component {
  static propTypes = {
    items: PropTypes.array,
    sessionItems: PropTypes.array,
    vendors: PropTypes.array,
    sessionId: PropTypes.number.isRequired
  }

  constructor(props) {
    super(props);
    
    this.state = {
      items: props.items,
      itemToAdd: undefined,
      cartItemToAdd: undefined,
      splitSessionItemToEdit: undefined,
      formItemType: undefined,
      currentCategory: undefined,
      currentVendor: undefined,
      searchTerms: "",
      sessionItems: props.sessionItems || [],
      vendors: props.vendors || [],
      uploading: false
    }
  }

  componentDidMount() {
    fetchCategories(this.handleCategoriesLoaded.bind(this))
    //this.handleCategoryChanged(undefined)
  }

  handleCategoriesLoaded(resp) {
    this.setState({ categories: resp.categories })
  }

  handleCategoryChanged(newCategory) {
    fetchItems(newCategory, this.state.currentVendor, function(resp) {
      this.setState({
        items:           resp.items,
        currentCategory: newCategory,
        searchTerms:           "",
        vendors:         resp.vendors
      })
    }.bind(this))
  }

  handleVendorChanged(e) {
    const newVendor = e.target.id
    if (this.state.searchTerms) {
      searchItems(this.state.searchTerms, newVendor, (resp) => {
        this.setState({
          currentCategory: null,
          currentVendor:   newVendor,
          items:           resp.items,
        })
      })
    } else {
      fetchItems(this.state.currentCategory, newVendor, function(resp) {
        this.setState({
          items:           resp.items,
          currentVendor:   newVendor,
          vendors:         resp.vendors
        })
      }.bind(this))
    }
  }

  handleItemFormOpened(item) {
    this.setState({
      itemToAdd: item,
      splitSessionItemToEdit: undefined,
      cartItemToAdd: undefined
    })
  }

  handleEditSessionItemFormOpened(item) {
    this.setState({
      splitSessionItemToEdit: item,
      itemToAdd: undefined,
      cartItemToAdd: undefined
    })
  }

  handleCartItemFormOpened(item) {
    this.setState({
      cartItemToAdd: item,
      splitSessionItemToEdit: undefined,
      itemToAdd: undefined
    })
  }

  handleItemFormClosed(item) {
    this.setState({
      itemToAdd: undefined,
      splitSessionItemToEdit: undefined,
      cartItemToAdd: undefined
    })
  }

  handleCartItemRemoved(itemToRemove, callback) {
    removeSplitSessionItem(itemToRemove.id, () => {
      var oldList = Array.from(this.state.sessionItems)
      var newList = Array.from(this.state.sessionItems)
      const i = oldList.indexOf(itemToRemove)
      newList.splice(i, 1)
      this.setState({sessionItems: newList})
      if (callback) {
        callback()
      }
    })
  }

  handleItemAdded(state, callback) {
    this.setState({uploading: true})

    submitSessionItems(this.props.sessionId, [{
        ...state, 
        'item_attributes[name]':          state.name,
        'item_attributes[price]':         state.price,
        'item_attributes[price_unit]':    state.price_unit,
        'item_attributes[quantity]':      state.quantity,
        'item_attributes[description]':   state.description,
        'item_attributes[image_url]':     state.image_url,
        'item_attributes[image]':         state.image,
        'item_attributes[quantity_unit]': state.quantity_unit,
        'item_attributes[web_url]':       state.web_url
       }], (result) => {
      this.setState({
        sessionItems: result.split_session_items,
        items:        result.items,
        uploading:    false
      })
      callback()
    })
  }

  handleCartItemEdited(state, callback) {
    this.setState({uploading: true})

    submitSessionItems(this.props.sessionId, [
      hashSubset(state, [
          'id', 'name', 'price', 'price_unit', 'quantity', 'quantity_unit', 'max_items', 'num_splits', 'description', 'split_price', 'split_quantity', 'image_url', 'web_url', 'image'
        ])
      ], (result) => {
      this.setState({
        sessionItems: result.split_session_items,
        items:        result.items,
        uploading:    false
      })
      callback()
    })
  }

  handleCartItemAdded(state, callback) {
    this.setState({uploading: true})

    let splitSessionData = hashSubset(state, [
        'name', 'price', 'price_unit', 'quantity', 'quantity_unit', 'max_items', 'num_splits', 'description', 'split_price', 'split_quantity', 'image_url', 'web_url', 'image'
      ])
    submitSessionItems(this.props.sessionId, [{
        ...splitSessionData,
        'item_id': state.id
      }], (result) => {
      this.setState({
        sessionItems: result.split_session_items,
        items:        result.items,
        uploading:    false
      })
      if (callback) {
        callback()
      }
    })
  }

  handleSearchTermsChanged(e) {
    const newTerms = e.target.value
    this.setState({ searchTerms: newTerms })
    if (this.timer) { clearTimeout(this.timer) }
    this.timer = setTimeout(() => {
      searchItems(newTerms, this.state.currentVendor, (resp) => {
        this.setState({
          currentCategory: null,
          items: resp.items,
        })
      })
    }, 500)
  }

  pickerClassName() {
    if (this.state.sessionItems && this.state.sessionItems.length > 0) {
      return("item-picker item-picker--open-cart")
    } else {
      return("item-picker")
    }
  }

  renderVendorList() {
    // TODO: Un-comment this if you want vendor list at the top again
    //return(
    //  <form className="item-picker__source">
    //    {Object.keys(this.state.vendors).sort().map((vendor, index) => {
    //      return(
    //        <div key={index} className="item-picker__source-option">
    //          <input
    //            id={vendor}
    //            type="radio"
    //            name="i-p-s"
    //            data-count={this.state.vendors[vendor]}
    //            onChange={this.handleVendorChanged.bind(this)}
    //          />
    //          <label htmlFor={vendor}>{vendor}</label>
    //        </div>
    //      )
    //    })}
    //  </form>
    //)
  }

  render () {
    return (
      <React.Fragment>
        <div className="create__items create__items--ip">
          <div className="item-picker__head">
            <div className="create__items-count">
              <i className="fa fa-shopping-cart"></i>
              <span>{this.state.sessionItems && this.state.sessionItems.length}</span>
            </div>
            {this.renderVendorList()}
            <div className="create__items-search">
              <input type="search" placeholder="Search" value={this.state.searchTerms} onChange={this.handleSearchTermsChanged.bind(this)}/>
              <i className="fa fa-search"></i>
            </div>
          </div>
          <div className={this.pickerClassName()}>
            <ItemPickerCart
              items={this.state.sessionItems}
              handleItemEdited={this.handleEditSessionItemFormOpened.bind(this)}
              handleItemRemoved={this.handleCartItemRemoved.bind(this)}
            />
            <ItemPickerCategories
              categories={this.state.categories}
              currentCategory={this.state.currentCategory}
              handleCategoryChanged={this.handleCategoryChanged.bind(this)}
            />
            {
              this.state.items == undefined ?
                <div className="item-picker__items-list"></div>
                :
                <ItemPickerList
                  items={this.state.items}
                  handleItemFormOpened={this.handleItemFormOpened.bind(this)}
                  handleCartItemFormOpened={this.handleCartItemFormOpened.bind(this)}
                />
            }
            {
              this.state.splitSessionItemToEdit && 
              <ItemForm
                item={this.state.splitSessionItemToEdit}
                itemList={this}
                handleFormClosed={this.handleItemFormClosed.bind(this)}
                handleItemAdded={this.handleCartItemEdited.bind(this)}
                uploading={this.state.uploading}
              />
            }
            {
              this.state.itemToAdd && 
              <ItemForm
                item={this.state.itemToAdd}
                itemList={this}
                handleFormClosed={this.handleItemFormClosed.bind(this)}
                handleItemAdded={this.handleItemAdded.bind(this)}
                uploading={this.state.uploading}
              />
            }
            {
              this.state.cartItemToAdd && 
              <ItemForm
                item={this.state.cartItemToAdd}
                itemList={this}
                handleFormClosed={this.handleCartItemFormOpened.bind(this)}
                handleItemAdded={this.handleCartItemAdded.bind(this)}
                uploading={this.state.uploading}
              />
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ItemPicker
