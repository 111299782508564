function jax(path, type, data, done, fail) {
  $.ajax({
    url: path,
    type: type,
    data: data,
    xhrFields: { withCredentials: true }
  })
  .done(function(r) { done(r); })
  .fail(function(r) {
    console.log('jax fail: ' + path);
    fail(r);
  })
  ;
}

function decrease($item, elem, target, callback) {
  $(elem).on('click', function(e) {
    var current = parseInt($item.find(target).val());
    if (current != 1) {
      $item.find(target).val(current - 1).trigger('change');
    }
    typeChange = 'less';
    callback && callback();
  });
}

function increase($item, elem, target, callback) {
  $(elem).on('click', function(e) {
    var current = parseInt($item.find(target).val());
    $item.find(target).val(current + 1).trigger('change');
    typeChange = 'more';
    callback && callback();
  });
}
