export function truncateString(str, strLimit) {
  if (str.length > strLimit) {
    return(str.substring(0, strLimit) + "...")
  } else {
    return(str)
  }
}

export function hashSubset(hash, fields) {
  return fields.reduce(function(o, k) { o[k] = hash[k]; return o; }, {})
}

export function scrollToRef(containerRef, itemRef) {
  containerRef.current.scrollTo(0, itemRef.current.offsetTop)
}