var session_name = 'Janie\'s Session',
    session_categories = [],
    finished = 0,
    mine = 0,
    clock_sz = 120,
    duration = { d: 1, h: 4, m: 1, s: 5 },
    d_mx = { d: 2, h: 23, m: 59, s: 59};

var session_items = [
  {
    name: 'Sadler\'s Smokehouse Mesquite Beef Brisket',
    brand: 'Brand Name',
    max_items: 4,
    splits: 1,
    claimed: 3,
    claimed_mine: 1,
    cper_split: 19.98,
    qper_split: 2.5,
    munit: 'lb',
    category: ['Fresh Food', 'Beef'],
  },
  {
    name: 'Captain Crunch',
    brand: 'Brand Name',
    max_items: 2,
    splits: 4,
    claimed: 5,
    claimed_mine: 0,
    cper_split: 2.54,
    qper_split: 1,
    munit: 'box',
    category: ['Breakfast', 'Cereal'],
  },
  {
    name: 'Coca-Cola',
    brand: 'Brand Name',
    max_items: 1,
    splits: 24,
    claimed: 17,
    claimed_mine: 6,
    cper_split: .64,
    qper_split: 16,
    munit: 'oz',
    category: ['Beverages', 'Soda'],
  },
  {
    name: 'Carrots',
    brand: 'Brand Name',
    max_items: 2,
    splits: 4,
    claimed: 5,
    claimed_mine: 3,
    cper_split: 1.24,
    qper_split: 4,
    munit: 'unit',
    category: ['Produce', 'Vegetables', 'Pantry'],
  },
  {
    name: 'Pepperonis',
    brand: 'Brand Name',
    max_items: 4,
    splits: 1,
    claimed: 4,
    claimed_mine: 0,
    cper_split: 19.98,
    qper_split: 2.5,
    munit: 'oz',
    category: ['Fresh Food', 'Lunch Meat'],
  },
  {
    name: 'Captain Crunch',
    brand: 'Brand Name',
    max_items: 2,
    splits: 4,
    claimed: 2,
    claimed_mine: 0,
    cper_split: 2.54,
    qper_split: 1,
    munit: 'box',
    category: ['Breakfast', 'Cereal', 'Another Category'],
  },
  {
    name: 'Coca-Cola\'s Super Special Edition With A Very Long Name That Should Probably Still Be Displayed',
    brand: 'Brand Name But Also A Very Long Brand Name Because Of Reasons',
    max_items: 1,
    splits: 24,
    claimed: 0,
    claimed_mine: 0,
    cper_split: .64,
    qper_split: 16,
    munit: 'oz',
    category: ['Beverages', 'Soda', 'Bakery'],
  },
  {
    name: 'Folgers',
    brand: 'Brand Name',
    max_items: 2,
    splits: 4,
    claimed: 6,
    claimed_mine: 0,
    cper_split: 1.24,
    qper_split: 4,
    munit: 'oz',
    category: ['Beverages', 'Sauces', 'Asian'],
  },
];

var splitty_dom = `
  <div class=session-item__splitty></div>
`;

var session_item_dom = `
  <div class="session-item">
    <div class="session-item__product-info">
      <div class="session-item__product">
        <div class="session-item__name" />
        <div class="session-item__brand" />
      </div>
      <div class="session-item__split-cost">
        <div class="session-item__splitty session-item__splitty--cper" />
        <div class="session-item__data-c-q">
          <div class="session-item__data-cost" />
          <div class="session-item__data-qty" />
        </div>
      </div>
    </div>
    <div class="session-item__session-info">
      <div class="session-item__splits">
        <div class="session-item__remaining"/>
        <div class="session-item__visual-splits">
          <div class="session-item__vs-container" />
          <div class="session-item__indicator" />
          <div class="session-item__numbered-splits">
            <span class="session-item__claimed-items" />
            <span class="session-item__max-items" />
          </div>
        </div>
        <button type="button" class="session-item__claim-action">Claim</button>
      </div>
    </div>
  </div>
`;

var claim_dom = `
  <div class="claim-item">
    <div class="claim-item__container">
      <div class="claim-item__close" />
      <div class="claim-item__info">
        <div class="claim-item__name" />
        <div class="claim-item__brand" />
        <div class="claim-item__remaining" />
      </div>
      <form action="#" class="claim-item__actions">
        <div class="claim-item__splits">
          <div class="claim-item__edit-container">
            <div class="claim-item__edit">
              <label>Splits</label>
              <i class="fa fa-minus-circle claim-item__less"></i>
              <input class="claim-item__num" type="number" min="0" value="1" required/>
              <i class="fa fa-plus-circle claim-item__more"></i>
            </div>
          </div>
          <div class="claim-item__indicator">
            <div class="claim-item__splits-container" />
            <div class="claim-item__counter">
              <div class="claim-item__cper" />
              <div class="claim-item__qper" />
            </div>
          </div>
        </div>
        <div class="claim-item__buttons">
          <input class="claim-item__save" type="submit" value="Add" />
          <button class="claim-item__close-btn" type="button">Cancel</button>
        </div>
      </form>
    </div>
  </div>
`;

var request_dom = `
  <div class="request-item">
    <div class="request-item__information">
      <div class="request-item__edit">Edit</div>
      <div class="request-item__branding">
        <div class="request-item__name" />
        <div class="request-item__brand" />
      </div>
      <div class="request-item__product-qc">
        <div class="request-item__qty" />
        <div class="request-item__cost" />
      </div>
    </div>
  </div>
`;

$('.session-head__title').html(session_name);
$('.session-head__total-items').html(session_items.length);


// setInterval(function() {
//   $('.session-head__timer-d').html(duration.d);
//   $('.session-head__timer-h').html(duration.h);
//   $('.session-head__timer-s').html(duration.s);
//   $('.session-head__timer-m').html(duration.m);
//   $('.d').css('stroke-dashoffset', clock_sz-(duration.d*(clock_sz/d_mx.d)));
//   $('.h').css('stroke-dashoffset', clock_sz-(duration.h*(clock_sz/d_mx.h)));
//   $('.m').css('stroke-dashoffset', clock_sz-(duration.m*(clock_sz/d_mx.m)));
//   $('.s').css('stroke-dashoffset', clock_sz-(duration.s*(clock_sz/d_mx.s)));
//   duration.s--;
//   if (duration.s === 0) {
//     duration.m--;
//     duration.s = d_mx.s;
//     if (duration.m === 0) {
//       duration.h--;
//       duration.m = d_mx.m;
//       if (duration.h === 0) {
//         duration.d--;
//         duration.h = d_mx.h;
//       }
//     }
//   }
// }, 1000);

function create_filter(name) {
  var $item = $('<li class="session__filter-item" />').html(name);
  $('.session__filter').append($item);
}

function add_cat(c) {
  var add = function (c) {
    return session_categories.some(function(el) {
      return el === c;
    });
  };
  if (add(c)) { return false; }
  session_categories.push(c);
  create_filter(c);
  return true;
}

function splitty_counter($dom, sel, item, id, claimed) {
  if (claimed == 0) { claimed++ }
  var max = item.remaining_splits + item.claimed_mine;
  var update = function() {
    totalc = (claimed * item.cper_split).toFixed(2);
    totalq = claimed * item.qper_split;
    $dom.find(sel + 'splits-container').empty();
    if (typeChange === 'less' && claimed != 0) {
      claimed -= 1;
    } else if (typeChange === 'more' && claimed < max) {
      claimed += 1;
    }
    if ((max - claimed) === 1) {
      $dom.find(sel + 'remaining')
        .html((max - claimed) + ' split remaining');
    } else {
      $dom.find(sel + 'remaining')
        .html((max - claimed) + ' splits remaining');
    }
    if (claimed > 0) {
      // TODO Need to do something if claimed gooes above 16...
      $dom.find('#' + id).val(claimed);
      $dom.find(sel + 'save').attr('value', 'Save');
      $dom.find(sel + 'cper').html('$' + (claimed * item.cper_split).toFixed(2));
      $dom.find(sel + 'qper').html((claimed * item.qper_split) + item.munit);
      for (var i = 0; i < $dom.find('#'+id).val(); i++) {
        $dom.find(sel + 'splits-container').append('<div class="claim-item__splitty"/>');
      }
    } else {
      $dom.find(sel + 'cper').html('$' + item.cper_split);
      $dom.find(sel + 'qper').html(item.qper_split + item.munit);
      $dom.find(sel + 'splits-container').append('<div class="claim-item__splitty claim-item__splitty--empty"/>');
    }
  };
  decrease($dom, $dom.find(sel + 'less'), '#' + id, update);
  increase($dom, $dom.find(sel + 'more'), '#' + id, update);
  typeChange = null; update();
  $dom.find(sel + 'actions').on('submit', function(ev) {
    item.claimed_mine = claimed;
    get_session_items();
    return false;
  });
  // TODO BUG: If they change the field manually...?
}

function claim(item, $parent) {
  var $dom = $(claim_dom),
      sel = '.claim-item__',
      el = 'claim-item__',
      elId = Math.floor(Math.random() * (100000000000 - 1 + 1)) + 1;
  $dom.find('label').attr('for', elId);
  $dom.find(sel + 'num').attr('id', elId);
  $dom.find(sel + 'name').html(item.name);
  $dom.find(sel + 'brand').html(item.brand);
  splitty_counter($dom, sel, item, elId, item.claimed_mine);
  $dom.find(sel + 'close').on('click', function(ev) { $dom.remove(); });
  $dom.find(sel + 'close-btn').on('click', function(ev) { $dom.remove(); });
  $parent.append($dom);
}

var totalMineCost;
function create_request(item) {
  var $request_dom = $(request_dom),
      sel = '.request-item__',
      el = 'request-item__',
      csplit = item.cper_split * item.claimed_mine;
  $request_dom.find(sel + 'qty')
    .html((item.qper_split * item.claimed_mine) + ' ' + item.munit);
  $request_dom.find(sel + 'cost')
    .html('$' + csplit.toFixed(2));
  $request_dom.find(sel + 'name').html(item.name);
  $request_dom.find(sel + 'brand').html(item.brand);
  $('.requests__container').append($request_dom);
  totalMineCost += csplit;
  $('.requests__total-cost').html(totalMineCost.toFixed(2));
}

function get_session_items() {
  $('.session__items-list').empty();
  $('.requests__container').empty();
  totalMineCost = 0;
  finished = 0;
  mine = 0;
  session_items.forEach(function(e, i) {
    // SHITTY FAKE SORT
    if (i == 0 || i == 3 || i == 5) {
      $('.session__items-list').append('<div class=session__category-head>Category</div>');
    }
    var $dom = $(session_item_dom),
        sel = '.session-item__',
        el = 'session-item__',
        total_splits = e.splits * e.max_items,
        remaining_splits = total_splits - (e.claimed + e.claimed_mine),
        claimed_items = Math.floor(((e.claimed + e.claimed_mine) / total_splits) * e.max_items);
    e.remaining_splits = remaining_splits;
    if (remaining_splits === 0) {
      finished += 1;
      $('.session-head__claimed-items').html(finished);
    }
    e.category.forEach(function(f, ii) { add_cat(f); });
    $dom.find(sel + 'name').html(e.name);
    $dom.find(sel + 'brand').html(e.brand);
    if (remaining_splits == 1) {
      $dom.find(sel + 'remaining')
        .html('1 Split Remaining')
        .addClass(el + 'remaining--attention');
    } else if (remaining_splits == 0) {
      $dom.find(sel + 'remaining')
        .html('All Claimed!')
        .addClass(el + 'remaining--done');
    } else {
      $dom.find(sel + 'remaining').html(remaining_splits + ' Splits Remaining');
    }
    if (e.claimed_mine > 0) {
      $dom.find(sel + 'claim-action').html('Edit');
    }
    if (claimed_items == e.max_items) {
      $dom.find(sel + 'session-info').addClass(el + 'session-info--done');
      $dom.find(sel + 'indicator').addClass(el + 'indicator--done');
      $dom.find(sel + 'numbered-splits').addClass(el + 'numbered-splits--done');
      $dom.find(sel + 'claim-action').addClass(el + 'claim-action--done');
      if (e.claimed_mine > 0) {
        $dom.find(sel + 'numbered-splits').addClass(el + 'numbered-splits--mine');
        $dom.find(sel + 'indicator').addClass(el + 'indicator--mine');
        $dom.find(sel + 'claim-action').removeClass(el + 'claim-action--done');
      }
    }
    $dom.find(sel + 'claimed-items').html(claimed_items);
    $dom.find(sel + 'max-items').html(e.max_items);
    for (i = 0; i < e.splits; i++) {
      var $sd = $(splitty_dom);
      if ((i + 1) <= ((e.claimed + e.claimed_mine) % e.splits) || remaining_splits === 0) {
        $sd.addClass('session-item__splitty--claimed');
        $dom.find(sel + 'vs-container').prepend($sd);
      } else {
        $dom.find(sel + 'vs-container').prepend($sd);
      }
      if ((i + 1) <= e.claimed_mine) {
        $sd.addClass('session-item__splitty--mine');
      }
    }
    $dom.find(sel + 'data-cost').html(e.cper_split);
    $dom.find(sel + 'data-qty').html(e.qper_split + e.munit);
    $dom.find(sel + 'claim-action').on('click', function(elm) {
      claim(e, $dom);
    });
    $('.session__items-list').append($dom);
    if (e.claimed_mine > 0) {
      mine += 1;
      $('.requests__counter').html(mine);
      create_request(e);
    }
  });
}
get_session_items();

lazy_settle_dom = `
  <div class=settle>
    <div class="settle__logo navigation__logo">
      <span>/</span> Splitty
    </div>
    <div class=settle__container>
      <div class=settle__list-column>
        <div class=settle__list>
          <h2>Shopping List</h2>
          <ul class=shopping-list>
            <div class=store>Costco</div>
            <div class=category>Fruits</div>
            <li>Apple</li>
            <li>Pears</li>
            <li>Bananas</li>
            <div class=category>Drinks</div>
            <li>Coca-Cola</li>
            <li>LaCroix</li>
            <div class=category>Meats</div>
            <li>Pork Belly</li>
            <li>Beef Brisket</li>
            <div class=store>Safeway</div>
            <div class=category>Meats</div>
            <li>Lunch Meat</li>
            <li>Pepperonis</li>
            <div class=category>Drinks</div>
            <li>Folgers Coffee</li>
            <div class=category>Cereal</div>
            <li>Captain Crunh</li>
            <li>Lucky Charms</li>
          </ul>
        </div>
        <div class=send-list><i class="fa fa-envelope"></i>Send List</div>
      </div>
      <div class=people-container>
        <div class=person-settle>
          <div class=name>Emmanuel Herrero</div>
          <div class="status pending">$24.32</div>
          <ul class=items>
            <li>Carrots<span>$2.43</span></li>
            <li>Juice<span>$2.43</span></li>
            <li>Bananas<span>$2.43</span></li>
            <li>Apples<span>$2.43</span></li>
            <li>Oranges<span>$2.43</span></li>
            <li>Pears<span>$2.43</span></li>
            <li>Cherios<span>$2.43</span></li>
            <li>Cheese<span>$2.43</span></li>
            <li>Milk<span>$2.43</span></li>
          </ul>
          <div class=setcontainer>
            <div class=settleup>Settle Up</div>
          </div>
        </div>
        <div class=person-settle>
          <div class=name>Danielle Latimer</div>
          <div class="status paid">$24.32</div>
          <ul class=items>
            <li>Carrots<span>$2.43</span></li>
            <li>Juice<span>$2.43</span></li>
            <li>Bananas<span>$2.43</span></li>
            <li>Apples<span>$2.43</span></li>
            <li>Oranges<span>$2.43</span></li>
            <li>Pears<span>$2.43</span></li>
            <li>Cherios<span>$2.43</span></li>
            <li>Cheese<span>$2.43</span></li>
            <li>Milk<span>$2.43</span></li>
          </ul>
        </div>
        <div class=person-settle>
          <div class=name>Chris </div>
          <div class="status pending">$24.32</div>
          <ul class=items>
            <li>Carrots<span>$2.43</span></li>
            <li>Juice<span>$2.43</span></li>
            <li>Bananas<span>$2.43</span></li>
            <li>Apples<span>$2.43</span></li>
            <li>Oranges<span>$2.43</span></li>
            <li>Pears<span>$2.43</span></li>
            <li>Cherios<span>$2.43</span></li>
            <li>Cheese<span>$2.43</span></li>
            <li>Milk<span>$2.43</span></li>
          </ul>
          <div class=setcontainer>
            <div class=settleup>Settle Up</div>
          </div>
        </div>
        <div class=person-settle>
          <div class=name>Kevin</div>
          <div class="status pending">$24.32</div>
          <ul class=items>
            <li>Carrots<span>$2.43</span></li>
            <li>Juice<span>$2.43</span></li>
            <li>Bananas<span>$2.43</span></li>
            <li>Apples<span>$2.43</span></li>
            <li>Oranges<span>$2.43</span></li>
            <li>Cheese<span>$2.43</span></li>
            <li>Milk<span>$2.43</span></li>
          </ul>
          <div class=setcontainer>
            <div class=settleup>Settle Up</div>
          </div>
        </div>
        <div class=person-settle>
          <div class=name>Nick</div>
          <div class="status pending">$24.32</div>
          <ul class=items>
            <li>Carrots<span>$2.43</span></li>
            <li>Juice<span>$2.43</span></li>
            <li>Oranges<span>$2.43</span></li>
            <li>Cheese<span>$2.43</span></li>
            <li>Milk<span>$2.43</span></li>
          </ul>
          <div class=setcontainer>
            <div class=settleup>Settle Up</div>
          </div>
        </div>
        <div class=person-settle>
          <div class=name>Janie</div>
          <div class="status paid">$24.32</div>
          <ul class=items>
            <li>Carrots<span>$2.43</span></li>
            <li>Juice<span>$2.43</span></li>
            <li>Bananas<span>$2.43</span></li>
            <li>Apples<span>$2.43</span></li>
            <li>Oranges<span>$2.43</span></li>
            <li>Pears<span>$2.43</span></li>
            <li>Cherios<span>$2.43</span></li>
            <li>Cheetos<span>$2.43</span></li>
            <li>Cherios<span>$2.43</span></li>
            <li>Cheese<span>$2.43</span></li>
            <li>Milk<span>$2.43</span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
`;

function launch_settle() {
  var $dom = $(lazy_settle_dom);
  $dom.find('.settle__logo').on('click', function() {
    $dom.addClass('GTFO');
    setTimeout(function() {
      $dom.remove();
    }, 600)
  });
  $('body').append($dom);
}

$('.goto-settle').on('click', launch_settle)
