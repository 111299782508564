var cart = [];

function addToCart($dom, item) {
  var $el = $(cart_item_markup);

  cart.push(item);
  jax(
    '/split_sessions/' + session_id + '/set_items',
    'POST',
    {
      session_items: cart
    },
    function(r) {
    },
    function(err) {
      alert('Could not add item to session');
      console.log(err);
    }
  );

  $('.item-picker__cart').append($el);

  $el.find('.ci-name').html(item.name);
  $el.find('.ci-split').html(item.split_price);
  $el.find('.ci-qty').html(item.num_splits)
    .attr('data-measurement', item.quantity_unit);
  $el.find('.ci-name').html(item.name);

  $dom.fadeOut('fast', function() { $(this).remove(); });
  $('.create__items-count').addClass('create__items-count--open');
  $('.item-picker').addClass('item-picker--open-cart');
  updateCartCount();

  $el.find('.ci-remove').on('click', function() {
    $el.slideUp('fast', function() {
      cart.splice( $.inArray(item, cart), 1);
      updateCartCount();
      $(this).remove();
    });
  });

  $el.find('.ci-edit').on('click', function() {
    createSessionItem(
      session_id,
      item.name,
      item.price,
      item.quantity,
      item.quantity_unit,
      parseInt(item.max_items),
      parseInt(item.num_splits)
    );

    $el.slideUp('fast', function() {
      cart.splice( $.inArray(item, cart), 1);
      $(this).remove();
    });
  });
}

$('.create__items-count').on('click', function() {
  if (cart.length != 0) {
    $('.create__items-count').toggleClass('create__items-count--open');
    $('.item-picker').toggleClass('item-picker--open-cart');
  }
});

function updateCartCount() {
  var len = cart.length;
  $('.create__items-count span').html(len);
  if (len === 0) {
    $('.item-picker').removeClass('item-picker--open-cart');
    $('.create__items-count').removeClass('create__items-count--open');
  }
}

var cart_item_markup = `
  <li>
    <div class="item-picker__cart-item">
      <div class="ci-options">
        <div class="ci-edit">Edit</div>
        <div class="ci-remove">
          <i class="fa fa-times-circle"></i>
        </div>
      </div>
      <div class="ci-name"></div>
      <div class="ci-details">
        <div class="ci-split"></div>
        <div class="ci-qty" data-measurement=""></div>
      </div>
    </div>
  </li>
`;

