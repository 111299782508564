/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import Rails from 'rails-ujs';
Rails.start();

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

import 'moment';
import URI from 'urijs';

require("jquery");
import '../common';

import '../create/session-name';
import '../item-picker/main';
import '../item-picker/create-item';
import '../item-picker/cart';

import '../main';
import '../create';
import '../session';
import '../components/MyDatePicker';
