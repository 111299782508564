import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

class ClaimPicker extends React.Component {
  static propTypes = {
    maxClaim:  PropTypes.number.isRequired,
    maxSplits: PropTypes.number.isRequired,
    claimedSplitsCount: PropTypes.number.isRequired,
    myClaimSplits: PropTypes.number.isRequired,
    itemId: PropTypes.number.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      hoverIndex: undefined,
      hoverSplits: undefined,
      selectedSplits: this.props.myClaimSplits,
      totalClaimed: this.props.claimedSplitsCount
    }
  }

  handleMouseEnter = (index) => {
    this.setState({
      hoverIndex: index,
      hoverSplits: index + 1 - this.props.claimedSplitsCount + this.props.myClaimSplits
    })
  }

  handleMouseLeave = () => {
    this.setState({
      hoverIndex: undefined,
      hoverSplits: undefined
    })
  }

  handleClick = (index) => {
    this.setState({
      hoverIndex: index,
      hoverSplits: index + 1 - this.props.claimedSplitsCount + this.props.myClaimSplits
    })
    this.props.changeClaimAmount(this.props.itemId, index + 1 - this.props.claimedSplitsCount + this.props.myClaimSplits)
  }

  handleChange = (e) => {
    this.setState({
      hoverIndex: undefined,
      hoverSplits: undefined
    })
    this.props.changeClaimAmount(this.props.itemId, e.target.value)
  }

  handleCancelSplits = () => {
    this.props.changeClaimAmount(this.props.itemId, 0)
  }

  getOthersSplitBubbles = (array, amount) => {
    for (var i = 1; i <= amount; i++) {
      let key = array.length
      array.push(
        <div key={key} className="session-item__splitty-container"><div className='session-item__splitty session-item__splitty--claimed'></div></div>
      )
    }
  }

  getSplitBubbles = (array, amount, defaultClasses) => {
    for (var i = 1; i <= amount; i++) {
      let bubbleClassnames = classNames(defaultClasses, {
        active: this.state.hoverIndex && array.length <= this.state.hoverIndex,
        hovered: this.state.hoverIndex
      })
      let key = array.length

      array.push(
        <div key={key} 
             className="session-item__splitty-container" 
             onMouseLeave={this.handleMouseLeave} 
             onMouseEnter={() => this.handleMouseEnter(key)} 
             onClick={() => this.handleClick(key)}>
               <div className={bubbleClassnames}></div>
        </div>
      )
    }
  }

  render () {
    const bubbles = []
    const { inputName, maxClaim, maxSplits, claimedSplitsCount, myClaimSplits } = this.props
    const hoverSplits = this.state.hoverSplits
    const othersSplits = claimedSplitsCount - myClaimSplits
    const availableSplits = maxSplits - myClaimSplits - othersSplits
    const sessionItemIndicatorClasses = classNames('session-item__indicator', {
      'session-item__indicator--done': maxSplits == claimedSplitsCount,
      'session-item__indicator--mine': maxSplits == claimedSplitsCount && myClaimSplits > 0
    })
    const remainingClasses = classNames('session-item__remaining', {
      'session-item__remaining--done': maxSplits == claimedSplitsCount,
      'session-item__remaining--attention': maxSplits -1 == claimedSplitsCount
    })
    const remainingText = maxSplits == claimedSplitsCount ? 'All claimed!' : maxClaim + ' splits remaining'

    this.getOthersSplitBubbles(bubbles, othersSplits)
    this.getSplitBubbles(bubbles, myClaimSplits, 'session-item__splitty session-item__splitty--claimed session-item__splitty--mine')
    this.getSplitBubbles(bubbles, availableSplits, 'session-item__splitty claimable')

    return (
      <div className="session-item__splits">
        <div className={remainingClasses}>{remainingText}</div>
        <div className="session-item__visual-splits">
          <div className="cancel-splits" onClick={this.handleCancelSplits}>x</div>
          <div className="session-item__vs-container">
            {bubbles}
          </div>
          <div className={sessionItemIndicatorClasses}></div>
          <div className="session-item__numbered-splits session-item__numbered-splits--done session-item__numbered-splits--mine">
            <span className="session-item__claimed-items"><input type="number" name={inputName} min="0" max={maxClaim + myClaimSplits} step="1" value={hoverSplits || myClaimSplits} onChange={this.handleChange} /></span>
            <span className="session-item__max-items">{ maxClaim + myClaimSplits }</span>
          </div>
        </div>
        <button type="button" className="session-item__claim-action hidden">Edit</button>
      </div>
    )
  }
}

export default ClaimPicker