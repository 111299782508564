import React from "react"
import PropTypes from "prop-types"
import { truncateString } from "../../utils"
class ItemPickerList extends React.Component {
  static propTypes = {
    handleItemFormOpened:     PropTypes.func.isRequired,
    handleCartItemFormOpened: PropTypes.func.isRequired,
    items:                    PropTypes.array
  }
  constructor(props) {
    super(props);
  }

  addItem(e) {
    var item = this.itemsForId(this.props.items, e.target.dataset.item_id)
    this.props.handleItemFormOpened(item)
  }

  addCartItem(e) {
    var item = this.itemsForId(this.props.items, e.target.dataset.item_id)
    this.props.handleCartItemFormOpened(item)
  }

  addCustomItem(e) {
    var newItem = {
      id:           null,
      name:         "",
      price:        1.0,
      priceUnit:    "$",
      quantity:     1,
      quantityUnit: "unit",
    }
    this.props.handleItemFormOpened(newItem)
  }

  itemsForId(items, itemId) {
    var foundItem = undefined
    items.forEach(function(item) {
      if (item.id == itemId){
        foundItem = item
      }
    });
    return foundItem
  }

  renderItemList() {
    if (this.props.items == undefined) {
      return("")
    }
    return (this.props.items).map((item, index) => {
      return(
        <li key={index}>
          <div className="ip-item">
            <div className="ip-item__name">{truncateString(item.name, 30)}</div>
            <div className="ip-item__details">
              <div className="ip-item__price">{parseFloat(item.price).toFixed(2)}</div>
              <div className="ip-item__amount">{item.quantity + " " + (item.quantityUnit ? item.quantityUnit.substr(0,5) : '')}</div>
            </div>
            <div className="ip-item__add" onClick={this.addCartItem.bind(this)} data-item_id={item.id}>Add to Session</div>
          </div>
        </li>
      )
    })
  }

  render () {
    return (
      <React.Fragment>
        <div className="item-picker__items-list">
          <div className="item-picker__custom-item">
            <div
              className="item-picker__create-c"
              onClick={this.addCustomItem.bind(this)}
            >Create Custom Item</div>
          </div>
          <ul className="item-picker__items">
              {this.renderItemList()}
          </ul>
        </div>
      </React.Fragment>
    );
  }

}

export default ItemPickerList
