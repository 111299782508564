import React from "react"
import PropTypes from "prop-types"

class StartSub extends React.Component {
  static propTypes = {
    stripePK:  PropTypes.string.isRequired,
    successUrl: PropTypes.string.isRequired,
    cancelUrl: PropTypes.string.isRequired,
    planId: PropTypes.string.isRequired,
    customerEmail: PropTypes.string.isRequired
  }

  handleBtnClick = () => {
    let stripe = Stripe(this.props.stripePK)

    stripe.redirectToCheckout({
      items: [{
        plan: this.props.planId,
        quantity: 1
      }],
      successUrl: this.props.successUrl,
      cancelUrl: this.props.cancelUrl,
      customerEmail: this.props.customerEmail
    }).then(function (result) {
      console.log("redirectResult", result)
    });
  }

  render() {
    return(
      <button
        id="start-subscription"
        className="navigation__action"
        onClick={this.handleBtnClick}
      >Start subscription to host more sessions!</button>
    )
  }
}

export default StartSub
