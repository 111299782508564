import React from "react"
import PropTypes from "prop-types"
import ItemPickerCategories from "./ItemPickerCategories"
import ItemPickerCart from "./ItemPickerCart"
import ItemPickerList from "./ItemPickerList"
import SubmitItemForm from "./SubmitItemForm"
import ItemPicker from "./ItemPicker"

class ItemSubmitter extends ItemPicker {
  pickerClassName() {
    if (this.state.sessionItems && this.state.sessionItems.length > 0) {
      return("item-picker item-picker--open-cart item-submitter")
    } else {
      return("item-picker item-submitter")
    }
  }

  render () {
    return (
      <React.Fragment>
        <div className="create__items create__items--ip">
          <div className="item-picker__head">
            <div className="create__items-count">
              <i className="fa fa-shopping-cart"></i>
              <span>{this.state.sessionItems && this.state.sessionItems.length}</span>
            </div>
            {this.renderVendorList()}
          </div>
          <div className={this.pickerClassName()}>
            <ItemPickerCart
              items={this.state.sessionItems}
              handleItemEdited={this.handleEditSessionItemFormOpened.bind(this)}
              handleItemRemoved={this.handleCartItemRemoved.bind(this)}
            />
            <ItemPickerCategories
              categories={this.state.categories}
              currentCategory={this.state.currentCategory}
              handleCategoryChanged={this.handleCategoryChanged.bind(this)}
            />
            {
              this.state.splitSessionItemToEdit && 
              <SubmitItemForm
                item={this.state.splitSessionItemToEdit}
                itemList={this}
                handleFormClosed={this.handleItemFormClosed.bind(this)}
                handleItemAdded={this.handleCartItemEdited.bind(this)}
                uploading={this.state.uploading}
              />
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ItemSubmitter