import jax      from '../common';
import decrease from '../common';
import increase from '../common';
var categories = [],
    session_id;

function loadItems(id) {
  session_id = id;
  jax(
    '/items',
    'GET',
    {},
    function(r) {
      populateItems(r.items);
    },
    function(err) {
      alert('We could not get items');
      console.log(err);
    }
  );
}

function populateCategories(category) {
  if (categories.indexOf(category) < 0) {
    categories.push(category);
    createCategory(category);
  }
}

function createCategory(category) {
  var $el = $('<li />').html(category);
  $el.on('click', function() {
    $el.toggleClass('active');
  });
  $('.item-picker__categories').append($el);
}

function populateItems(items) {
  items.forEach(function(e, i) {
    populateCategories(e.category);
    createItem(e);
  });
}

function createItem(item) {
  var $el = $(itemPickerItem);
  $('.item-picker__items').append($el);
  $el.find('.ip-item__name').html(item.name);
  $el.find('.ip-item__price').html(item.price);
  $el.find('.ip-item__amount')
    .html(item.quantity)
    .attr('data-measurement', item.quantity_unit);
  $el.on('click', function() {
    createSessionItem(
      session_id,
      item.name,
      item.price,
      item.quantity,
      item.quantity_unit
    );
  });
}

var itemPickerItem = `
  <li>
    <div class="ip-item">
      <div class="ip-item__name"></div>
      <div class="ip-item__details">
        <div class="ip-item__price"></div>
        <div class="ip-item__amount" data-measurement=""></div>
      </div>
      <div class="ip-item__add">Add to Session</div>
    </div>
  </li>
`;

