import React from "react"
import Lightbox from 'react-image-lightbox'
import PropTypes from "prop-types"
import classNames from "classnames"
import ClaimPicker from "./claims/ClaimPicker"
import Timer from '../common/Timer'
import ClaimsSummaryItem from './claims/ClaimsSummaryItem'
import { scrollToRef } from '../../utils'

class UserClaims extends React.Component {
  static propTypes = {
    sessionItems:     PropTypes.array,
    myClaims:         PropTypes.array,
    splitSessionId:   PropTypes.number.isRequired,
    splitSessionName: PropTypes.string.isRequired,
    splitSessionParticipantsCount: PropTypes.number.isRequired,
    currentUserIsOwner: PropTypes.bool.isRequired,
    sessionEndsAt: PropTypes.string,
    fullyClaimedCount: PropTypes.number
  }

  constructor(props) {
    super(props)

    const itemRefs = this.props.sessionItems.map(item => { return {id: item.id, ref: React.createRef()} })
    
    this.state = {
      lightboxOpened: false,
      lightboxImage: undefined,
      myClaims: props.myClaims,
      sessionItems: props.sessionItems,
      itemRefs: itemRefs,
      containerRef: React.createRef()
    }
  }

  handleDecBtn(e) {
    var inputField = e.target.nextSibling
    var attrName   = inputField.name
    var currentVal = Number.parseFloat(inputField.value)
    var step       = Number.parseFloat(inputField.step)
    var newVal     = currentVal - step
    var data = {inputField: inputField, attrName: attrName, currentVal: currentVal, step: step, newVal: newVal }

    if (newVal >= Number.parseFloat(inputField.min))
      inputField.value = newVal
  }

  handleIncBtn(e) {
    var inputField = e.target.previousSibling
    var attrName   = inputField.name
    var currentVal = Number.parseFloat(inputField.value)
    var step       = Number.parseFloat(inputField.step)
    var newVal     = currentVal + step
    var data = {inputField: inputField, attrName: attrName, currentVal: currentVal, step: step, newVal: newVal }

    if (!inputField.max || newVal <= Number.parseFloat(inputField.max))
      inputField.value = newVal
  }

  openLightbox = () => {
    this.setState({
      lightboxOpened: true
    })
  }

  closeLightbox = () => {
    this.setState({
      lightboxOpened: false
    })
  }

  setLightboxImage = (url) => {
    this.setState({
      lightboxImage: url
    }, () => {
      this.openLightbox()
    })
  }

  handleSummaryEdit = (itemId) => {
    scrollToRef(this.state.containerRef, this.state.itemRefs.find(item => item.id == itemId).ref)
  }

  changeClaimAmount = (itemId, myClaims) => {
    let existingClaim = this.state.myClaims.find(claim => claim.split_session_item_id == itemId)

    if (existingClaim) {
      this.setState({
        myClaims: this.state.myClaims.map(claim => {
          if (claim.split_session_item_id == itemId) {
            return {...claim, splits: myClaims}
          }
          return claim
        }),
        sessionItems: this.state.sessionItems.map(item => {
          if (item.id == itemId) {
            let originalClaimSplits = this.state.myClaims.find(claim => claim.split_session_item_id == itemId).splits
            return {...item, 
              claimed_splits_count: myClaims - originalClaimSplits + item.claimed_splits_count
            }
          }
          return item
        })
      })
    } else {
      this.setState({
        myClaims: [...this.state.myClaims, {split_session_item_id: itemId, splits: myClaims}],
        sessionItems: this.state.sessionItems.map(item => {
          if (item.id == itemId) {
            return {...item, claimed_splits_count: myClaims + (item.claimed_splits_count || 0)}
          }
          return item
        })
      })
    }
  }

  render () {
    const items = this.state.sessionItems.map((item) => {
      const maxSplits     = item.total_splits
      const maxClaim      = maxSplits - item.claimed_splits_count
      const myClaim       = this.state.myClaims.find((claim) => claim.split_session_item_id == item.id)
      const inputName     = `split_session_items[${item.id}][user_claims_attributes]splits`
      const myClaimSplits = myClaim ? myClaim.splits : 0
      const imageurl      = item.image_url || (item['image'] && item['image'].url) || item.item.image_url || (item.item['image'] && item.item['image'].url)
      const sessionItemPictureClasses = classNames('session-item__picture', {
        hidden: imageurl == undefined
      })
      let ref = this.state.itemRefs.find(itemRef => itemRef.id == item.id).ref
      const url = item.web_url || item.item['web_url']

      return (
        <div ref={ref} className="session-item" key={item.id}>
          <div className={sessionItemPictureClasses}>
            <img src={imageurl} onClick={() => this.setLightboxImage(imageurl)} alt='Picture' />
          </div>
          <div className="session-item__product-info-container">
            <div className="session-item__product-info">
              <div className="session-item__product">
                <div className="session-item__name">{ item.quantity } { item.quantity_unit } of "{ item.name } - { item.item.price_unit || '$' }{ parseFloat(item.price).toFixed(2) }"{url && <a target="_blank" className="session-item__details-link" href={url}>DETAILS</a>}</div>
                <div className="session-item__brand">{ item.item.brand }</div>
              </div>
              <div className="session-item__split-cost">
                <div className="session-item__splitty session-item__splitty--cper"></div>
                <div className="session-item__data-c-q">
                  <div className="session-item__data-cost">{ item.item.price_unit || '$' }{ parseFloat(item.split_price).toFixed(2) }</div>
                  <div className="session-item__data-qty">{ item.split_quantity } { item.quantity_unit }</div>
                </div>
              </div>
            </div>
            <div className="session-item__session-info session-item__session-info--done">
              <ClaimPicker itemId={item.id} inputName={inputName} maxClaim={maxClaim} maxSplits={maxSplits} claimedSplitsCount={item.claimed_splits_count} myClaimSplits={myClaimSplits} changeClaimAmount={this.changeClaimAmount} />
            </div>
          </div>
          <div key={item.id} className="hidden">
            <div className="clearfix">
              <div className="float-left">
                { item.quantity } { item.quantity_unit } of "{ item.name }" - { item.item.price_unit || '$' }{ item.price }
                <br/>
                One split: { item.item.price_unit || '$' }{ item.split_price }, { item.split_quantity } { item.quantity_unit }, you can claim up to { maxClaim } more
              </div>
              <div className="float-right">
                <i className="fa fa-minus-circle" onClick={this.handleDecBtn.bind(this)}></i>
                
                <i className="fa fa-plus-circle" onClick={this.handleIncBtn.bind(this)}></i>
              </div>
            </div>
            <hr/>
          </div>
        </div>
      )
    })

    let totalClaimedPrice = 0
    const claimsSummary = this.state.sessionItems.map(item => {
      const myClaim = this.state.myClaims.find((claim) => claim.split_session_item_id == item.id)
      const myClaimSplits = myClaim ? myClaim.splits : 0
      if (myClaimSplits != 0) totalClaimedPrice += myClaimSplits * item.split_price

      return <ClaimsSummaryItem key={item.id} item={item} myClaimSplits={myClaimSplits} handleEdit={this.handleSummaryEdit} />
    })

    const sessionHeadClasses = classNames('session-head__title', {'session-head__title--admin': this.props.currentUserIsOwner})

    return (
      <form action={`/split_sessions/${this.props.splitSessionId}/user_claims`} method="post">
        <div className="session-head">
          <h2 className={sessionHeadClasses}>{ this.props.splitSessionName }</h2>
          <div className="session-head__items">
            <i className="fa fa-shopping-basket"></i>
            <span className="session-head__claimed-items">{ this.state.myClaims.filter(claim => claim.splits != 0).length }</span>
            <span className="session-head__total-items">{ this.state.sessionItems.length }</span>
          </div>
          <div className="session-head__users">
            <i className="fa fa-user"></i>
            <span className="session-head__users-count">{ this.props.splitSessionParticipantsCount }</span>
          </div>
          <Timer until={this.props.sessionEndsAt} />
        </div>
        <div className="session">
          <div className="session__items">
            <ul className="session__filter hidden"><li className="session__filter-item">Fresh Food</li><li className="session__filter-item">Beef</li><li className="session__filter-item">Breakfast</li><li className="session__filter-item">Cereal</li><li className="session__filter-item">Beverages</li><li className="session__filter-item">Soda</li><li className="session__filter-item">Produce</li><li className="session__filter-item">Vegetables</li><li className="session__filter-item">Pantry</li><li className="session__filter-item">Lunch Meat</li><li className="session__filter-item">Another Category</li><li className="session__filter-item">Bakery</li><li className="session__filter-item">Sauces</li><li className="session__filter-item">Asian</li></ul>
            <div ref={this.state.containerRef} className="session__items-list">
              <div className="session__category-head">Claim items</div>
              { items }
            </div>
          </div>
          <div className="session__requests">
            <div className="requests">
              <div className="requests__header">
                <h2 className="requests__title">My Requests</h2>
                <div className="requests__count">
                  <i className="fa fa-shopping-basket"></i>
                  <span className="requests__counter">{this.state.myClaims.filter(claim => claim.splits != 0).length}</span>
                </div>
              </div>
              <div className="requests__container">
                {claimsSummary}
              </div>
              <div className="requests__total">
                <span className="requests__total-label">Total</span>
                <span className="requests__total-cost">{parseFloat(totalClaimedPrice).toFixed(2)}</span>
              </div>
            </div>


            <input type="submit" className="goto-settle"/>
          </div>
        </div>
        {this.state.lightboxOpened && (
          <Lightbox
            mainSrc={this.state.lightboxImage}
            onCloseRequest={this.closeLightbox}
          />
        )}
      </form>
    )
  }
}

export default UserClaims