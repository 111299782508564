var session_name = null,
    session_date = null,
    session_duration = null,
    session_items = [],
    session_invites = [];

$('#s-duration').on('change', function(e) {
  if ($(e.target).val() === '1') {
    $('.s-d-label').html('Day');
  } else {
    $('.s-d-label').html('Days');
  }
});

function create_category(e, i) {
  var $item = $('<li />').html(e.name);
  $('.item-picker__categories').append($item);
  if (e.subcat.length !== 0) {
    var $subc = $('<ul class="item-picker__sub-c" />');
    $item.addClass('has-sub');
    $item.append($($subc).hide());
    e.subcat.forEach(function(e, ii) {
      $subc.append($('<li />').html(e));
    });
    $item.on('click', function(e) {
      if ($(e.target).hasClass('has-sub')) {
        $item.toggleClass('active');
        $item.toggleClass('expanded');
        $subc.slideToggle('fast');
      }
    })
  } else {
    $item.on('click', function(e) {
      $item.toggleClass('active');
    });
  }
}

function add_person() {
  var person = $('#s-email').val();
  var $item = $('<li><div class="remove-invite"></div></li>');
  session_invites.push(person);
  $item.append(person);
  $('.s-invite__participants').append($item);
  $item.find('.remove-invite').on('click', function(e) {
    $item.remove();
    session_invites.splice( $.inArray(person, session_invites), 1);
    update_invite_count();
  });
  $('#s-email').val('');
}

function update_invite_count() {
  var l = session_invites.length;
  $('.create__invite-count span').html(l);
}

// $('.s-invite__add-e').on('submit', function(e) {
//   add_person();
//   update_invite_count();
//   return false;
// });

// $('.s-invite__action-send').on('click', function(e) {
//   if (session_invites.length !== 0) {
//     $('.s-invite__participants').addClass('sent');
//     $('.create__start-session').removeClass('create__start-session--disabled');
//   } else {
//     alert("There's no one to invite!");
//   }
// });
