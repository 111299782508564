import React from "react"
import PropTypes from "prop-types"
class ItemPickerCategories extends React.Component {
  static propTypes = {
    categories: PropTypes.object,
    currentCategory: PropTypes.string,
    handleCategoryChanged: PropTypes.func.isRequired
  }
  constructor(props) {
    super(props);
  }

  categoryChanged(e) {
    var category = e.target.dataset.category
    this.props.handleCategoryChanged(category)
  }

  renderCategoryList() {
    return Object.keys(this.props.categories).sort().map((category_name, index) => {
      return(
        <li key={index} className={this.props.currentCategory == category_name ? "active" : ""} onClick={this.categoryChanged.bind(this)} data-category={category_name}>{category_name}</li>
      )
    })
  }

  render () {
    if (this.props.categories == undefined || this.props.categories[0] == undefined) {
      return("")
    } else {
      return (
        <React.Fragment>
         <ul className="item-picker__categories">
           {this.renderCategoryList()}
         </ul>
        </React.Fragment>
      )
    }
  }
}

export default ItemPickerCategories
