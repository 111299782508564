$('.item-picker__create-c').on('click', function() {
  createSessionItem(session_id);
});

function createSessionItem(session_id, name, price, qty, msmt, maxItems, splits) {
  var $el = $(createItemMarkup);

  $('.item-picker').append($el);

  $el.find('.add-item__qty-split').attr('data-measurement',
      $('#ai-measurement').val());
  if (name) $el.find('#ai-name').val(name);
  if (price) $el.find('#ai-price').val(price);
  if (qty) $el.find('#ai-qty').val(qty);
  if (msmt) {
    $el.find('#ai-msmt').val(msmt);
    $el.find('.add-item__qty-split').attr('data-measurement', msmt);
  }
  if (price && qty) updateSplits(price, qty, 2);

  $el.find('.add-item__close').on('click', function() {
    $el.remove();
    updateCartCount();
  });

  $el.find('#ai-msmt').on('change keyup', function() {
    $el.find('.add-item__qty-split')
      .attr('data-measurement', $(this).val());
  });

  decrease($el, '#less-item', '#ai-max');
  decrease($el, '#less-split', '#ai-split');
  increase($el, '#plus-item', '#ai-max');
  increase($el, '#plus-split', '#ai-split');

  $el.find('input').on('change keyup', function() {
    var elQty = $el.find('#ai-qty').val(),
        elSplits = $el.find('#ai-split').val();
    updateSplits(price, elQty, elSplits);
  });

  $el.on('submit', function() {
    var item = {
      session_id: session_id,
      name: $el.find('#ai-name').val(),
      price: $el.find('#ai-price').val(),
      quantity: $el.find('#ai-qty').val(),
      quantity_unit: $el.find('#ai-msmt').val(),
      max_items: $el.find('#ai-max').val(),
      num_splits: $el.find('#ai-split').val(),
      split_price: $el.find('.add-item__cost-split').html(),
      split_quantity: $el.find('.add-item__qty-split').val(),
    }
    addToCart($el, item);
    return false;
  });
}

function updateSplits(price, qty, splits) {
  $('.add-item__cost-split')
    .html('$' + parseFloat(price / splits)
    .toFixed(2));
  $('.add-item__qty-split')
    .html(parseFloat(qty / splits)
    .toFixed(1));
}

var createItemMarkup  = `
  <form class="add-item" action="">
    <div class="add-item__close">
      <i class="fa fa-close"></i>
    </div>
    <div class="add-item__container">
      <div class="row">
        <label for="ai-name">Name</label>
        <input id="ai-name" type="text" required/>
      </div>
      <div class="row">
        <label for="ai-price">Price</label>
        <input id="ai-price" placeholder="4.49" type="number" min="0.01" step="0.01" required/>
      </div>
      <div class="row add-item__measurement">
        <label for="ai-qty">Item Qty.</label>
        <input id="ai-qty" type="number" step="any"/>
        <input id="ai-msmt" type="text" placeholder="unit" required/>
      </div>
      <div class="row add-item__amount">
        <label for="ai-max">Max Items</label>
        <i id="less-item" class="fa fa-minus-circle"></i>
        <input id="ai-max" type="number" min="1" value="1" required/>
        <i id="plus-item" class="fa fa-plus-circle"></i>
      </div>
      <div class="row add-item__amount">
        <label for="ai-split">Splits</label>
        <i id="less-split" class="fa fa-minus-circle"></i>
        <input id="ai-split" type="number" min="1" value="2" required/>
        <i id="plus-split" class="fa fa-plus-circle"></i>
      </div>
      <div class="add-item__split-details">
        <div class="add-item__cost-split"></div>
        <div class="add-item__qty-split" data-measurement=""></div>
      </div>
      <div class="row center add-item__action">
        <p class="add-item__disclaimer"><span>*</span>All prices are approximates</p>
        <input class="add-item__confirm" type="submit" value="Confirm and Add">
      </div>
    </div>
  </form>
`;

